import type { User } from "@/entities/User/User.model";
import { getOnboardingStatus } from "./getOnboardingStatus";
import { Company, OnboardingState, OnboardingStatus } from "@/entities/Company";

export function getOnboardingState(
    user: User,
    company?: Company,
): OnboardingState {
    const status = getOnboardingStatus(user, company);
    const state = {
        status,
        emailVerified: status > OnboardingStatus.VERIFY_EMAIL,
        basicInfoComplete: status > OnboardingStatus.BASIC_INFO,
        aboutYourBusinessComplete:
            status > OnboardingStatus.ABOUT_YOUR_BUSINESS,
        unsupportedCountry: status === OnboardingStatus.UNSUPPORTED_COUNTRY,
        shareYourGoalsComplete: status > OnboardingStatus.SHARE_YOUR_GOALS,
        fundraisingHistoryComplete:
            status > OnboardingStatus.FUNDRAISING_HISTORY,
        MNDASigned: status > OnboardingStatus.MNDA,
        dataRoomComplete: status > OnboardingStatus.DATAROOM,
        isComplete: status === OnboardingStatus.COMPLETE,
    };

    return {
        ...state,
        profileComplete:
            state.emailVerified &&
            state.basicInfoComplete &&
            state.aboutYourBusinessComplete &&
            state.shareYourGoalsComplete &&
            state.fundraisingHistoryComplete,
        userId: user.id,
        organizationId: user.organizationId,
    };
}
