import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";

export const Container = ({
    children,
    centered,
}: {
    children: React.ReactNode;
    centered?: boolean;
}) => {
    return (
        <AnimatePresence>
            <motion.main
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={clsx(
                    "max-h-screen overflow-hidden",
                    centered
                        ? "flex justify-center px-10 lg:px-0"
                        : "px-10 pt-20 lg:px-[100px] xl:px-[180px] 2xl:px-[300px]",
                )}
            >
                {children}
            </motion.main>
        </AnimatePresence>
    );
};
