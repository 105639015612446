import { datadogRum } from "@datadog/browser-rum";
import type { User } from "./User.model";
import { IFlagsmith } from "flagsmith/types";
import { Company } from "../Company/Company.types";

export function trackUserIdentity(
    company: Company | null | undefined,
    user: User | null | undefined,
    flagsmith: IFlagsmith<string, string>,
) {
    if (user) {
        if (!flagsmith.identity) {
            flagsmith.identify(user.email, {
                id: user.id,
                email: user.email ?? "",
                isAdmin: !!user.isAdmin,
                isCompany: !!user.isCompany,
                isFund: !!user.isFund,
                firstName: user.firstName ?? "",
                lastName: user.lastName ?? "",
            });
        }

        if (process.env.NODE_ENV === "production") {
            datadogRum.setUser({
                ...user,
                id: user.id.toString(),
                name: `${user.firstName} ${user.lastName}`,
                company: {
                    id: company?.id.toString(),
                    name: company?.name,
                    uuid: company?.uuid,
                },
            });
        }
    }
}
