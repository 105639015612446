export const validateDOB = (
    value: string,
): { isValid: boolean; message: string } => {
    if (!value) return { isValid: true, message: "" };
    const [month, day, year] = value.split("/").map(Number);

    const daysInMonth = {
        1: 31,
        2: 28,
        3: 31,
        4: 30,
        5: 31,
        6: 30,
        7: 31,
        8: 31,
        9: 30,
        10: 31,
        11: 30,
        12: 31,
    };

    // Check month
    if (month < 1 || month > 12) {
        return { isValid: false, message: "Invalid month" };
    }

    // Check day
    if (month === 2) {
        const isLeapYear =
            year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
        if (day < 1 || day > (isLeapYear ? 29 : 28)) {
            return { isValid: false, message: "Invalid day for February" };
        }
    } else if (day < 1 || day > daysInMonth[month]) {
        return { isValid: false, message: "Invalid day for this month" };
    }

    // Check age
    const today = new Date();
    const eighteenYearsAgo = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate(),
    );
    const hundredYearsAgo = new Date(
        today.getFullYear() - 100,
        today.getMonth(),
        today.getDate(),
    );

    const dateToCheck = new Date(year, month - 1, day);
    if (dateToCheck > eighteenYearsAgo) {
        return { isValid: false, message: "You must be at least 18 years old" };
    }
    if (dateToCheck < hundredYearsAgo) {
        return { isValid: false, message: "Age cannot exceed 100 years" };
    }

    return { isValid: true, message: "" };
};
