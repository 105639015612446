import CloseIcon from "@/ui/atoms/Icon/CloseIcon";

export type FileItemProps = {
    file: File;
    onRemove: () => void;
};

export const FileItem = ({ file, onRemove }: FileItemProps) => (
    <div className="flex items-center justify-between p-2">
        <div className="flex items-center">
            <p className="body-s">{file.name}</p>
        </div>
        <CloseIcon onClick={onRemove} />
    </div>
);
