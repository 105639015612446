import React from "react";
import { IconProps } from "./types";
import clsx from "clsx";

const CloseIcon = ({
    className,
    width = "20",
    height = "20",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    className={clsx(
                        "size-4 stroke-gray-200 hover:stroke-white",
                        className,
                    )}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4.72461 15.5117L14.7246 5.51172M4.72461 5.51172L14.7246 15.5117"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default CloseIcon;
