import type { User } from "@/entities/User/User.model";
import type { Company } from "@/entities/Company/Company.types";
import {
    CompanyFileDocumentType,
    OnboardingStatus,
} from "@/entities/Company/Company.constants";
import { SENSITIVE_COUNTRIES } from "@/constants";
import { LastFundingRound } from "@/entities/CapitalApplication";

export const getOnboardingStatus = (user?: User | null, company?: Company) => {
    if (!user || !user.emailVerified) return OnboardingStatus.VERIFY_EMAIL;

    if (!user.organizationId) return OnboardingStatus.BASIC_INFO;

    if (!company) return OnboardingStatus.ABOUT_YOUR_BUSINESS;

    const {
        investmentAmount,
        fundingType,
        fundraisingTimeFrame,
        useOfFunds,
        yearFounded,
        selfReportedIndustry,
        revenueModel,
        lastRaised,
        lastFundingRound,
        latestPostMoneyValuation,
        equityRaised,
    } = company.capitalApplication;

    const hasBusinessInfo =
        company.country && yearFounded && selfReportedIndustry && revenueModel;

    if (!hasBusinessInfo) {
        return OnboardingStatus.ABOUT_YOUR_BUSINESS;
    } else if (
        company.country &&
        SENSITIVE_COUNTRIES.includes(company.country)
    ) {
        return OnboardingStatus.UNSUPPORTED_COUNTRY;
    }

    const hasFundaisingGoals =
        investmentAmount && fundingType && fundraisingTimeFrame && useOfFunds;

    if (!hasFundaisingGoals) return OnboardingStatus.SHARE_YOUR_GOALS;

    const hasFundraisingHistory =
        lastFundingRound === LastFundingRound.Bootstrapped ||
        (lastRaised &&
            lastFundingRound &&
            equityRaised &&
            latestPostMoneyValuation);

    if (!hasFundraisingHistory) return OnboardingStatus.FUNDRAISING_HISTORY;

    if (!company.acceptedNda) return OnboardingStatus.MNDA;

    const data = company.activationStatus;
    const missingFiles = data.missingFileTypesInDataroom || [];
    const isIncomeAndBalanceMissing =
        missingFiles.includes(CompanyFileDocumentType.IncomeStatement) &&
        missingFiles.includes(CompanyFileDocumentType.BalanceSheet);

    if (
        !(
            data.hasCompanyConfiguredConnector ||
            data.hasCompanyConfiguredDataRoom ||
            !isIncomeAndBalanceMissing
        )
    ) {
        return OnboardingStatus.DATAROOM;
    }

    return OnboardingStatus.COMPLETE;
};
