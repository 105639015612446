import { useApiMutation } from "@/api";
import { sendPasswordReset } from "@/entities/User";
import { Form, Input, Spinner } from "@/ui";
import { MultiStepForm } from "@/ui";
import clsx from "clsx";
import * as yup from "yup";

const { Container, Header } = MultiStepForm;

function ResetPasswordPage() {
    const { mutate: resetPassword, isPending: isResettingPassword } =
        useApiMutation(sendPasswordReset);

    const handleSubmit = (data) => {
        resetPassword({
            email: data.email,
        });
    };

    return (
        <Container centered>
            <div
                className={clsx(
                    "flex flex-col gap-8",
                    "items-center justify-center",
                    "md:flex-row",
                    "h-screen",
                )}
            >
                <div className="mb-8 w-full md:mb-0 md:w-[450px]">
                    <Header
                        title="Reset Your Password"
                        description="Enter the email address associated with your Hum Capital account, and we’ll send you a link to reset your password."
                    />
                </div>
                <div className="w-full md:w-[500px]">
                    <ResetPasswordForm
                        onSubmit={handleSubmit}
                        isSubmitting={isResettingPassword}
                    />
                    <div className="mx-auto mt-4 w-full text-center">
                        <a href="/login">Back to Hum Capital</a>
                    </div>
                </div>
            </div>
        </Container>
    );
}

const ResetPasswordForm = ({ onSubmit, isSubmitting }) => {
    return (
        <Form
            id="reset-password:form"
            schema={ResetPasswordForm.schema}
            onSubmit={onSubmit}
        >
            <Input
                name="email"
                label="Email address"
                placeholder="Enter your email"
                disabled={isSubmitting}
            />
            <button
                type="submit"
                className="lg mt-6 w-full"
                disabled={isSubmitting}
                form="reset-password:form"
            >
                {isSubmitting ? "Sending" : "Continue"}
                {isSubmitting && <Spinner />}
            </button>
        </Form>
    );
};

ResetPasswordForm.schema = yup.object({
    email: yup.string().email("Please enter a valid email address").required(),
});

export default ResetPasswordPage;
