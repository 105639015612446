import { useApiQuery } from "@/api";
import { getCompany } from "@/entities/Company";
import { useSession } from "@/entities/Session";
import { useMemo } from "react";
import { getOnboardingState } from "../CompanyOnboarding/getOnboardingState";

export function useCompany() {
    const session = useSession();
    const company = useApiQuery(getCompany, {
        enabled: !!session.user?.organizationId,
    });
    const isInitialLoading = useMemo(
        () =>
            (!session.user && session.isLoading) ||
            (!company?.data && company.isLoading),
        [session.user?.id, session.isLoading, company.isLoading],
    );

    const onboarding = useMemo(() => {
        if (!session.user) return undefined;
        return getOnboardingState(session.user, company.data);
    }, [company.data?.id, session.user?.id]);

    return {
        isInitialLoading,
        isLoading: company.isLoading,
        company: company.data,
        onboarding,
    };
}
