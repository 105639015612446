export enum ApplicationStatus {
    Engage = "engage",
    Pass = "pass",
}

export enum CapitalDeployment {
    Once = "once",
    Monthly = "monthly",
    NotSure = "not_sure",
}

export enum ApplicationRecordSystem {
    Swell = "Swell",
    Kizen = "Kizen",
    ThreeDCart = "3dcart",
    Checkbook = "Checkbook",
    StoreHippo = "StoreHippo",
    Exact = "Exact",
    CompanyHub = "CompanyHub",
    Affirm = "Affirm",
    AgileCRM = "AgileCRM",
    Quickbooks = "Quickbooks",
    SAP = "SAP",
    Xero = "Xero",
    NetSuite = "NetSuite",
    Workday = "Workday",
    Billomat = "Billomat",
    Clearbooks = "Clearbooks",
    Recurly = "Recurly",
    MX = "MX",
    Mixpanel = "Mixpanel",
    Paywhirl = "PayWhirl",
    Nimble = "Nimble",
    Revcent = "RevCent",
    Mailchimp = "Mailchimp",
    Holded = "Holded",
    Xcart = "X-Cart",
    Candis = "Candis",
    GoogleAnalytics = "Google Analytics",
    GoogleAdsense = "Google Adsense",
    Liftoff = "LiftOff",
    Enigma = "Enigma",
    Agenda = "Agenda",
    FastBill = "FastBill",
    MicrosoftDynamics = "Microsoft Dynamics",
    Other = "Other",
}

export enum ApplicationPaymentProcessor {
    PayPal = "PayPal",
    WooCommerce = "WooCommerce",
    Stripe = "Stripe",
    Squarespace = "Squarespace Online Stores",
    Shopify = "Shopify",
    Square = "Square",
    Braintree = "Braintree",
    Klarna = "Klarna",
    Afterpay = "Afterpay",
    AuthorizeNet = "Authorize.net",
    Billomat = "Billomat",
    Clearbooks = "Clearbooks",
    BillCom = "Bill.com",
    Worldpay = "Worldpay",
    Affirm = "Affirm",
    AgileCRM = "Agile CRM",
    CompanyHub = "CompanyHub",
    AmazonPay = "Amazon Pay",
    Finch = "Finch",
    Other = "Other",
}

export enum ApplicationEconomicTiers {
    Between0And1M = "between_0_and_1_m",
    Between1And3M = "between_1_and_3_m",
    Between3And5M = "between_3_and_5_m",
    Between5And15M = "between_5_and_15_m",
    Between15And25M = "between_15_and_25_m",
    Between25And50M = "between_25_and_50_m",
    Between50And100M = "between_50_and_100_m",
    MoreThan100M = "more_than_100_m",
}

export enum ApplicationRunwayMonths {
    LessThan6 = "lessThan6",
    Between6and12 = "between6and12",
    MoreThan12 = "moreThan12",
}

export enum ChannelType {
    icm_organic = "icm_organic",
    icm_sales = "icm_sales",
    icm_company_invitation = "icm_company_invitation",
    icm_investor_invitation = "icm_investor_invitation",
    webform = "webform",
    social = "social",
    referral = "referral",
    events = "events",
    paid_list = "paid_list",
    paid_ads = "paid_ads",
    outbound_sales = "outbound_sales",
    unknown = "unknown",
}

export enum OnboardingStatus {
    Complete = "complete",
    Incomplete = "incomplete",
    LegacyComplete = "legacy_complete",
    LegacyIncomplete = "legacy_incomplete",
    CompleteButWaitingForMdaToBeDownloaded = "complete_but_waiting_for_mda_to_be_downloaded",
}

export enum CompanyRevenueModel {
    Saas = "saas",
    Transactional = "transactional",
    Licensing = "licensing",
    TakeRate = "take_rate",
}

export enum CompanyRevenueModelV6 {
    TransactionalConsumer = "transactional_consumer",
    TransactionalEnterprise = "transactional_enterprise",
    RecurringSaaS = "recurring_saas",
    RecurringSubscription = "recurring_subscription",
    RecurringLicensingRoyalties = "recurring_licensing_royalties",
    TakeRate = "take_rate",
    LendingFinance = "lending_finance",
    ServiceProject = "service_project",
    ServiceFee = "service_fee",
    Other = "other",
}

export enum EquityRaised {
    Bootstrapped = "bootstrapped",
    Between0And5M = "between_0_and_5_m",
    Between5And15M = "between_5_and_15_m",
    Between15And25M = "between_15_and_25_m",
    Between25And50M = "between_25_and_50_m",
    Between50And100M = "between_50_and_100_m",
    MoreThan100M = "more_than_100_m",
}

export enum LeverageableAssets {
    NotApplicable = "not_applicable",
    AccountsReceivable = "accounts_receivable",
    Inventory = "inventory",
    RealEstate = "real_estate",
    CapitalEquipment = "capital_equipment",
}

export enum LastFundingRound {
    Bootstrapped = "bootstrapped",
    PreSeed = "preseed",
    Seed = "seed",
    SeriesA = "series_a",
    SeriesB = "series_b",
    SeriesC = "series_c",
    SeriesD = "series_d",
    SeriesE = "series_e",
}

export enum FundingType {
    Debt = "debt",
    Equity = "equity",
    Either = "either",
    IDontKnow = "i_dont_know",
}

export enum InvestmentType {
    Angel = "angel",
    GrowthEquity = "growth_equity",
    LeveragedBuyOut = "leveraged_buy_out",
    MezzDebt = "mezz_debt",
    PreIPO = "pre_IPO",
    Seed = "seed",
    SeniorBankDebt = "senior_bank_debt",
    SeriesA = "series_a",
    SeriesBPlus = "series_b_plus",
    SpecialSituations = "special_situations",
    VentureDebt = "venture_debt",
    SmallCapPublic = "small_cap_public",
    ReceivablesFinance = "receivables_finance",
}

export enum UseOfFunds {
    OrganicGrowth = "organic_growth",
    ResearchAndDevelopment = "research_and_development",
    InventoryOrWorkingCapital = "inventory_or_working_capital",
    PurchaseOrders = "purchase_orders",
    Payables = "payables",
    TurnaroundFinancing = "turnaround_financing",
    CapitalEquipment = "capital_equipment",
    RealEstate = "real_estate",
    Acquisition = "acquisition",
    Lending = "lending",
    Recapitalization = "recapitalization",
    DebtRefinancing = "debt_refinancing",
    Other = "other",
}

export enum FundraisingTimeFrameMonths {
    Between0and3 = "between_0_and_3_months",
    Between3and6 = "between_3_and_6_months",
    Between6and12 = "between_6_and_12_months",
    MoreThan12 = "more_than_12_months",
}

export enum CompanyEquityRaised {
    Bootstrapped = "bootstrapped",
    Between0And5M = "between_0_and_5_m",
    Between5And15M = "between_5_and_15_m",
    Between15And25M = "between_15_and_25_m",
    Between25And50M = "between_25_and_50_m",
    Between50And100M = "between_50_and_100_m",
    MoreThan100M = "more_than_100_m",
}

export const CAPITAL_DEPLOYMENT = {
    [CapitalDeployment.Once]: "All at once (one installment)",
    [CapitalDeployment.Monthly]: "Monthly installments",
    [CapitalDeployment.NotSure]: "Not sure",
};

export const FUNDRAISING_TIME_FRAME_MONTHS = {
    [FundraisingTimeFrameMonths.Between0and3]: "Less than 3 months",
    [FundraisingTimeFrameMonths.Between3and6]: "Between 3 and 6 months",
    [FundraisingTimeFrameMonths.Between6and12]: "Between 6 and 12 months",
    [FundraisingTimeFrameMonths.MoreThan12]: "12 months or more",
};

export const REVENUE_MODEL_V6 = {
    [CompanyRevenueModelV6.TransactionalConsumer]: "Transactional (Consumer)",
    [CompanyRevenueModelV6.TransactionalEnterprise]:
        "Transactional (Enterprise)",
    [CompanyRevenueModelV6.RecurringSaaS]: "SaaS (Recurring)",
    [CompanyRevenueModelV6.RecurringSubscription]: "Subscription (Recurring)",
    [CompanyRevenueModelV6.RecurringLicensingRoyalties]:
        "Licensing / Royalties (Recurring)",
    [CompanyRevenueModelV6.TakeRate]: "Take Rate (eg. Marketplace)",
    [CompanyRevenueModelV6.LendingFinance]: "Specialty Finance / Lending",
    [CompanyRevenueModelV6.ServiceProject]: "Service (Project-based)",
    [CompanyRevenueModelV6.ServiceFee]: "Service (Fee-for-service)",
    [CompanyRevenueModelV6.Other]: "Other",
};

export const FUNDING_TYPE = {
    [FundingType.Debt]: "Debt",
    [FundingType.Equity]: "Equity",
    [FundingType.Either]: "Either",
    [FundingType.IDontKnow]: "I don't know",
};

export const LAST_FUNDING_ROUND = {
    [LastFundingRound.Bootstrapped]: "Bootstrapped (No external capital taken)",
    [LastFundingRound.PreSeed]: "Pre-seed",
    [LastFundingRound.Seed]: "Seed",
    [LastFundingRound.SeriesA]: "Series A",
    [LastFundingRound.SeriesB]: "Series B",
    [LastFundingRound.SeriesC]: "Series C",
    [LastFundingRound.SeriesD]: "Series D",
    [LastFundingRound.SeriesE]: "Series E",
};

export const USE_OF_FUNDS = {
    [UseOfFunds.Acquisition]: "Acquisition",
    [UseOfFunds.CapitalEquipment]: "Capital Equipment",
    [UseOfFunds.DebtRefinancing]: "Debt Refinancing",
    [UseOfFunds.InventoryOrWorkingCapital]: "Inventory or Working Capital",
    [UseOfFunds.Lending]: "Lending",
    [UseOfFunds.OrganicGrowth]: "Organic Growth",
    [UseOfFunds.Payables]: "Payables",
    [UseOfFunds.PurchaseOrders]: "Purchase Orders",
    [UseOfFunds.ResearchAndDevelopment]: "R&D",
    [UseOfFunds.RealEstate]: "Real Estate",
    [UseOfFunds.Recapitalization]: "Recapitalization",
    [UseOfFunds.TurnaroundFinancing]: "Turnaround Financing",
    [UseOfFunds.Other]: "Other",
};

export const EQUITY_RAISED = {
    [CompanyEquityRaised.Bootstrapped]:
        "Bootstrapped (No external capital taken)",
    [CompanyEquityRaised.Between0And5M]: "$0–$5M",
    [CompanyEquityRaised.Between5And15M]: "$5–$15M",
    [CompanyEquityRaised.Between15And25M]: "$15–$25M",
    [CompanyEquityRaised.Between25And50M]: "$25–$50M",
    [CompanyEquityRaised.Between50And100M]: "$50–$100M",
    [CompanyEquityRaised.MoreThan100M]: "$100M+",
};
