import { atom } from "jotai";

export function localStorageAtom(
    key: string,
    initialValue: string | null = null,
) {
    const atomBase = atom<string | null>(
        localStorage.getItem(key) ?? initialValue,
    );

    return atom(
        (get) => get(atomBase),
        (get, set, newStr: string) => {
            set(atomBase, newStr);
            localStorage.setItem(key, newStr);
        },
    );
}
