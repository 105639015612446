import {
    ApplicationRecordSystem,
    ApplicationRunwayMonths,
    ApplicationStatus,
    CapitalDeployment,
    CompanyRevenueModel,
    CompanyRevenueModelV6,
    EquityRaised,
    FundingType,
    FundraisingTimeFrameMonths,
    InvestmentType,
    LastFundingRound,
    UseOfFunds,
} from "./CapitalApplication.constants";

export type UtmParams = {
    utmMedium: string | null;
    utmSource: string | null;
    utmCampaign: string | null;
    utmTerm: string | null;
    utmContent: string | null;
};

export type CapitalApplication = {
    // Business Info
    yearFounded?: number;
    selfReportedIndustry?: string;
    revenueModel?: CompanyRevenueModelV6 | CompanyRevenueModel;

    // Fundraising Goals
    investmentAmount?: number;
    fundingType?: FundingType;
    fundraisingTimeFrame?: FundraisingTimeFrameMonths;
    useOfFunds?: UseOfFunds;

    // Fundraising History
    lastRaised?: string;
    equityRaised?: EquityRaised;
    lastFundingRound?: LastFundingRound;
    latestPostMoneyValuation?: number;

    // Other
    capitalDeployment?: CapitalDeployment;
    reviewAndConfirmBy?: string;
    reviewAndConfirmNeeded?: boolean;
    yearlyRevenue?: number;
    yearlyRevenueForSyndication?: number | null;
    unwantedInvestors?: { label: string; value: string }[];
    requireMutualNda?: boolean;
    monthsOfRunway?: ApplicationRunwayMonths;
    status?: ApplicationStatus;
    recordSystem?: ApplicationRecordSystem;
    paymentProcessor?: string;
    qualityOfMetrics?: number;
    qualityOfTeam?: number;
    investmentTypes?: InvestmentType[];
    dataroomEnable?: boolean;
    insightsEnable?: boolean;

    id: number;
};
