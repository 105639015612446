import clsx from "clsx";
import { ErrorMessage } from "./ErrorMessage";
import { Label } from "./Label";

type NativeInputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;

export function RawCheckbox({
    name,
    label,
    errorMessage,
    showErrorRing,
    required,
    ...props
}: {
    name: string;
    label: string;
    errorMessage?: string;
    showErrorRing?: boolean;
} & Omit<NativeInputProps, "name" | "label" | "type">) {
    const describedBy = props["aria-describedby"] || `${name}-error`;

    return (
        <div className="relative flex items-end gap-3">
            <div>
                <input
                    id={name}
                    name={name}
                    type="checkbox"
                    aria-required={required}
                    className={clsx(
                        "ml-1 size-4 cursor-pointer rounded bg-transparent text-blue-500",
                        "border-default checked:border-default border-2",
                        "ring-0 focus:ring-0",
                        (errorMessage || showErrorRing) &&
                            "ring-1 ring-red-600",
                    )}
                    {...props}
                />
            </div>
            <Label name={name} label={label} required={required} />
            {errorMessage && (
                <ErrorMessage id={describedBy} message={errorMessage} />
            )}
        </div>
    );
}
