import { useContext, useState } from "react";
import { FormContext } from "./Form";
import { NumericFormat } from "react-number-format";
import clsx from "clsx";
import { ObjectSchema } from "yup";
import { FormState } from "./Form.types";
import { isRequired } from "./utils";
import { ErrorMessage } from "./ErrorMessage";

export function Money<T extends ObjectSchema<any>>({
    label,
    readonly = false,
    placeholder,
    parent = "",
    ...props
}: {
    name: string;
    label: string;
    placeholder?: string;
    readonly?: boolean;
    parent?: string;
    disabled?: boolean;
}) {
    const [touched, setTouched] = useState(false);
    const [inputError, setInputError] = useState(false);
    const form = useContext(FormContext) as FormState<T> | undefined;
    const name = parent ? `${parent}.${props.name}` : props.name;
    if (!form) return null;

    const error = form.errors?.[name] || "";
    const showError =
        !inputError &&
        touched &&
        error &&
        !error.toLowerCase().includes("required");
    const showErrorRing = touched && (error || inputError);
    const describedBy = `${name}-error`;
    const required = isRequired(form.schema, name);

    return (
        <div className="flex flex-col">
            <div className="flex gap-1">
                <label htmlFor={name}>{label}</label>
                {required && <p className="ui-element text-red-400">*</p>}
            </div>
            <NumericFormat
                aria-describedby={describedBy}
                allowNegative={false}
                thousandSeparator=","
                prefix="$"
                decimalScale={0}
                name={name}
                id={name}
                disabled={props.disabled}
                onValueChange={(v) => {
                    const value: string | number = v.value;
                    form.updateValue(name, value ? +value : undefined);
                }}
                value={form.values[name] ?? ""}
                type="text"
                placeholder={placeholder}
                readOnly={readonly}
                onBlur={() => {
                    setInputError(false);
                }}
                onFocus={() => {
                    setTouched(true);
                }}
                isAllowed={(value) => {
                    if (value.floatValue || value.value === "") {
                        setInputError(false);
                        return true;
                    } else {
                        setInputError(true);
                        return false;
                    }
                }}
                className={clsx(
                    "mb-1 mt-2 w-full bg-slate-900 p-2 pl-3 text-white",
                    "border-default rounded border outline-none",
                    "placeholder:text-slate-500",
                    "focus:ring-transparent focus:placeholder:text-transparent",
                    showErrorRing && "ring-1 ring-red-600",
                    props.disabled && "cursor-not-allowed",
                )}
                required={required}
                aria-required={required}
            />
            <div
                className={clsx(
                    "overflow-hidden transition-all duration-300",
                    showError ? "h-6 opacity-100" : "h-0 opacity-0",
                )}
            >
                {showError && <ErrorMessage id={describedBy} message={error} />}
                {inputError && (
                    <ErrorMessage
                        id={describedBy}
                        message="Only numeric values are accepted"
                    />
                )}
            </div>
        </div>
    );
}
