import { PlayCircleIcon } from "@heroicons/react/24/solid";
import Vimeo from "@u-wave/react-vimeo";
import clsx from "clsx";

export const Video = ({
    src,
    isNotPlaying,
    onPlay,
    videoId,
}: {
    src: string;
    isNotPlaying: boolean;
    onPlay: () => void;
    videoId: string;
}) => {
    return (
        <div
            className={clsx(
                "w-full",
                "h-full",
                "mb-8",
                "relative inline-block overflow-hidden rounded",
            )}
        >
            {isNotPlaying && (
                <div role="button" onClick={onPlay}>
                    <img
                        width="100%"
                        src={src}
                        className={clsx(
                            "no-lazy",
                            "rounded",
                            "overflow-hidden",
                            "peer transition-transform duration-200 hover:scale-125",
                        )}
                        alt="investor video"
                        decoding="async"
                        srcSet="https://humcapital.com/wp-content/uploads/2023/01/Investor-Video-thumbnail.png 880w, https://humcapital.com/wp-content/uploads/2023/01/Investor-Video-thumbnail-300x169.png 300w, https://humcapital.com/wp-content/uploads/2023/01/Investor-Video-thumbnail-600x338.png 600w, https://humcapital.com/wp-content/uploads/2023/01/Investor-Video-thumbnail-768x432.png 768w, https://humcapital.com/wp-content/uploads/2023/01/Investor-Video-thumbnail-400x225.png 400w, https://humcapital.com/wp-content/uploads/2023/01/Investor-Video-thumbnail-110x62.png 110w"
                        sizes="(max-width: 880px) 100vw, 880px"
                    />
                    <PlayCircleIcon
                        height={88}
                        className={clsx(
                            "-translate-x-1/2 -translate-y-1/2",
                            "absolute left-1/2 top-1/2 z-10 fill-slate-500 hover:fill-slate-600",
                        )}
                    />
                </div>
            )}

            <Vimeo
                video={videoId}
                width="500"
                showTitle={false}
                showPortrait={false}
                paused={isNotPlaying}
                responsive
                className={clsx("rounded", {
                    hidden: isNotPlaying,
                })}
            />
        </div>
    );
};
